export default {
  actions: {
    ok: 'OK'
  },
  accessibility: {
    consultPage: 'Go to page',
    loadingPage: 'Loading page content',
    logoClicSanteAlt: 'Click here to return to the home page',
    alternateLogoClicSanteAlt: 'Click here to open the Christmas card',
    cancelApptMailImgAlt: 'Appointment confirmation email with a cancel button at the bottom'
  },
  base: {
    clicsante: {
      formattedName: 'Clic Santé',
      serviceName: 'Clic Santé',
      newsletterName: 'Info Clic Santé',
      welcomeTitle: 'Welcome to the Clic Santé Network',
      error404: 'Error, nonexistent page'
    },
    cookieConsent: {
      message:
        '<b>Use of cookies : </b>Our website uses cookies essential for its functions and the use of its features. We also use cookies to gather aggregated data on user interactions to improve the site’s performance through our partners.',
      linkLabel:
        'We do not use cookies to identify, locate, or profile you. For more information on our use of cookies, please see our Cookie Policy',
      buttonLabel: 'OK',
      urlPath: '/confidentialite'
    },
    errors: {
      title: 'Something went wrong',
      failed: 'An error occurred, please try again',
      requiredField: 'This field is required',
      invalidEmail: 'Invalid email format',
      invalidPostalCode: 'The postal code is invalid',
      postalCodeNotFound: 'Could not find the provided postal code',
      invalidAppointmentNumber: 'The appointment number is invalid',
      invalidNam: 'The health insurance number is invalid',
      invalidPhone: 'The phone number is invalid',
      minCharacters: 'Please enter at least 3 characters',
      invalidWordSearch: 'Please refine your search',
      internalCodes: {
        geocode: {
          not_found: 'Could not find {address}'
        }
      }
    },
    success: {
      cancelAppointment: {
        withMultipleFactor: 'An email or a text message has been sent to you.'
      }
    }
  },
  carousel: {
    profile: '{0} or {1} Clic Santé account to benefit from additional features',
    loginLink: 'Login',
    registerLink: 'create your account'
  },
  banner: {
    text: ' to your Clic Santé account in order to easily manage bookings for you and your family',
    register: 'Subscribe'
  },
  createProfile: {
    title: 'Create a free & secure account',
    text1: 'Simplify your appointment bookings.',
    text2: 'Gain access to your appointment history.',
    text3: 'Manage appointments for your whole family.',
    ctaSignUp: 'Create my account',
    checkbox: "Don't show this message again",
    ctaSignInText: 'Already a member?',
    ctaSignInURLText: 'Sign in'
  },
  marketing: {
    placeholder: {
      region: 'Enter your region',
      email: 'Enter your email'
    },
    btn: {
      subscribe: 'Subscribe',
      back: 'Back to home page'
    },
    register: {
      success: 'Your subscription request was successful',
      error: 'Your subscription request failed... Please try again later'
    },
    regionNotAvailable: 'The subscription service is not available...'
  },
  header: {
    accessibilitySkipToContentLink: 'Skip to main content',
    clicsante: {
      title1: 'Book your health',
      title2: ' appointment now!',
      menu: {
        profileConnect: 'Sign in / Log in',
        takeAppt: 'Book an Appointment',
        solution: 'Our solutions',
        solutionLink: 'https://clicsante.net/',
        cancelAppt: 'Cancel appointment',
        contactUs: 'Contact us',
        services: 'View all services',
        faq: 'FAQ',
        job: 'Job offers',
        terms: 'Terms and conditions of use',
        policy: 'Privacy Policy',
        cookiePolicy: 'Cookie Policy',
        locale: 'EN'
      }
    },
    profile: {
      menu: {
        myProfile: 'My Account',
        disconnect: 'Log Out'
      },
      loginText: 'Login',
      loggedText: 'My account',
      createMyAccount: 'Create my account',
      notification: {
        title: 'Login or create your profile to benefit from additional features',
        button: 'Login / Register'
      }
    }
  },
  footer: {
    clicsante: {
      slogan: 'Join the Health Click network!',
      social: 'Like our Facebook page',
      social2: '<< For better access to health care >>',
      citizensMenus: {
        title: 'Citizens',
        takeAppt: 'Book an appointment',
        cancelAppt: 'Cancel appointment',
        services: 'Service registry',
        vaccineProof: 'Vaccine proof',
        vaccineProofUrl: 'https://covid19.quebec.ca/PreuveVaccinale/en',
        solution: 'Our solutions',
        faq: 'FAQ'
      },
      jobsMenus: {
        title: 'Careers',
        job: 'Apply now!'
      },
      usersMenus: {
        terms: 'Terms and conditions of use',
        policy: 'Privacy Policy',
        cookiePolicy: 'Cookie Policy'
      },
      mobileMenus: {
        takeAppt: 'Book appointment',
        cancelAppt: 'Cancel appointment',
        loginText: 'Login',
        loggedText: 'My account'
      }
    }
  },
  cancel: {
    title: 'You can cancel your appointment from:',
    itemOne: 'The confirmation email you first received',
    itemTwo: 'The appointment reminder email',
    itemThree: 'The online booking page of your local health facility',
    subtitle: 'How to find your local appointment scheduling page?',
    stepOne:
      'Locate your health care facility using the search engine in the home page. Enter <i><strong>the service and your postal code</strong></i> then press on <i><strong>Search</strong></i>',
    stepTwo: 'Click on <i><strong>"Cancel an Appointment"</strong></i> at the top of the page',
    stepThree: 'Fill in the form',
    cantCancel: 'You are unable to cancel your COVID-19 vaccination appointment?',
    phoneNumber: '1 877 644-4545 (free of charge)',
    couponLostSuccess: 'Email sent successfully',
    couponLostSuccessNoContent: 'No appointments were found for this email',
    errors: {
      invalidCouponNumber: 'Invalid coupon number',
      appointmentDateInvalid: 'Invalid appointment date',
      couponNotAssociated: 'No appointment associated to this coupon'
    }
  },
  cancelAppointment: {
    title: 'There are 3 ways you can cancel an appointment:',
    withAppointment: {
      title:
        'By clicking on the Cancel button in a confirmation or reminder email (see image below)'
    },
    withMultipleFactor: {
      title: 'With your email address and your coupon number',
      subtitle:
        'Important, you must enter the same email address as the one entered when booking the appointment.',
      description:
        "Enter your confirmation number in the following text box and click on the ''Cancel my appointment'' button."
    },
    withoutCoupon: {
      title: 'Lost your coupon number ?',
      subtitle:
        'Find your upcoming appointments by using the e-mail address used when booking your appointment.',
      description: 'Enter your email to receive your appointment list.'
    },
    errors: {
      invalidCouponNumber: 'Invalid coupon format',
      invalidEmail: 'Invalid email format',
      invalidPhone: 'Invalid cellphone format',
      invalidDate: 'Invalid date format',
      requiredField: 'Field is required'
    },
    inputs: {
      couponLabel: 'Coupon number',
      dateLabel: 'Date of appointment',
      thirdFactorLabel: 'Email or cellphone',
      emailLabel: 'Email'
    },
    cancelButton: 'Cancel My Appointment',
    reminderButton: 'Send me an email'
  },
  contactUs: {
    title: 'contact us',
    subtitle1: 'I am a citizen',
    subtitle2: 'I am a health establishment',
    content1:
      'If you need assistance booking an appointment on Clic santé, please contact <strong>Services Québec</strong> toll-free at <a href="tel:18776444545">1-877-644-4545</a>',
    content2:
      'If you have any questions about the services offered, or if you wish to cancel or modify an appointment (if you do not have your coupon number or confirmation e-mail), please contact the <strong>health establishment</strong> where you booked your appointment.',
    content3: 'To join the <strong>sales department</strong> write to the following address :',
    content4: 'Please note that messages that do not concern sales will not be processed.'
  },
  notFound: {
    title: 'Page not found (Error 404)',
    content1: 'The search performed could not be finalized.',
    content2: 'The link you entered has been moved or changed',
    thanks: 'Thank you for your understanding.',
    linkToPortal: 'Return Home'
  },
  errors: {
    generic: 'An error occurred, please try again',
    appointments: {
      'update-job': {
        'not-found': {
          title: 'Session expired',
          messages:
            'The allocated time to book your appointment has expired. If your previous request was not completed, please start again".'
        },
        forbidden: {
          title: 'Error',
          messages: ''
        },
        'token-invalid': {
          title: 'Invalid code',
          message:
            'The code you entered is incorrect. Please enter a valid identity verification code.<br />If the code you entered is the same as the one in your identity verification email, please restart the identification process.'
        },
        'ip-mismatch': {
          title: 'Error',
          message: 'IP address does not match'
        },
        'not-in-priority-group': {
          title: 'Important message',
          message:
            "You're not eligible yet to book your appointment.<br /><br />" +
            'Please consult the following website for modification date details: <br><a href="https://xn--qubec-csa.ca/vaccinCOVID" target="_blank">Québec.ca/COVIDvaccine</a>'
        },
        'pre-authorization-invalid': {
          title: 'Error',
          message:
            '<strong>We are having difficulty identifying you.</strong><br /><br />' +
            'The invitation link could be invalid, expired or already in use. Errors in capturing the information prevent us from completing your online appointment request in a secure manner.<br /><br />' +
            'If you have been vaccinated at a <strong>pharmacy</strong> and are having difficulty to take or modify your appointment, please contact the pharmacy directly.<br /><br />' +
            'Here are the options available to you: <br /><br />' +
            '1- Create your Clic Santé account now or Log in if you already have an account to be authorized to make an appointment despite the current identification problems.<br /><br />' +
            '2- You can go to a <strong>walk-in vaccination clinic</strong>. <a href="https://www.quebec.ca/en/health/health-issues/a-z/2019-coronavirus/progress-of-the-covid-19-vaccination#c104580" target="_blank">List of walk-in vaccination clinics</a>.<br /><br />'
        },
        'previous-dose-in-the-future': {
          title: 'Important Message',
          message:
            '<strong>You’re not eligible to book your appointment.</strong><br /><br />' +
            'Please consult the following website for modification date details: <a href="https://xn--qubec-csa.ca/vaccinCOVID" target="_blank">Québec.ca/vaccinCOVID</a>'
        },
        'postal-code-not-in-remote-area': {
          title: 'Important Message',
          message:
            'The postal code entered is not one of the areas served for citizens 18 and older living in one of the identified remote areas.'
        }
      }
    },
    validation: {
      'invalid-appointmentReference': {
        'custom-validator-failure': {
          title: 'Important Message',
          message:
            '<p><strong>One of the information entered is incorrect.</strong></p>' +
            '<p>Please verify the information in the following fields:</p>' +
            '<ul style="list-style-type: \'-  \';">' +
            '<li><strong>Health Insurance Number</strong><div>Validate each character of the number on your health insurance card</div></li>' +
            '<li><strong>Coupon number</strong><div>Validate the composition in letters and numbers of the appointment coupon number\n</div></li>' +
            '<li><strong>Email address</strong><div>Validate the format of the email address</div></li>' +
            '<li><strong>Phone number</strong><div>Validate the format of the phone number</div></li>' +
            '</ul>' +
            '<p></p>' +
            '<p>If you are still having difficulty, here are your options:</p>' +
            '<ul>' +
            '<li>Create your Clic Santé account now or Log in if you already have an account to be authorized to make an appointment despite the current identification problems.</li>' +
            '<li>You can go to a <strong>walk-in vaccination clinic</strong>. <a href="https://www.quebec.ca/en/health/health-issues/a-z/2019-coronavirus/progress-of-the-covid-19-vaccination#c104580" target="_blank">List of walk-in vaccination clinics</a>.</li>' +
            '</ul>'
        }
      },
      'invalid-phone': {
        invalid: {
          title: 'Important Message',
          message:
            '<p><strong>One of the information entered is incorrect.</strong></p>' +
            '<p>Please verify the information in the following fields:</p>' +
            '<ul style="list-style-type: \'-  \';">' +
            '<li><strong>Health Insurance Number</strong><div>Validate each character of the number on your health insurance card</div></li>' +
            '<li><strong>Coupon number</strong><div>Validate the composition in letters and numbers of the appointment coupon number\n</div></li>' +
            '<li><strong>Email address</strong><div>Validate the format of the email address</div></li>' +
            '<li><strong>Phone number</strong><div>Validate the format of the phone number</div></li>' +
            '</ul>' +
            '<p></p>' +
            '<p>If you are still having difficulty, here are your options:</p>' +
            '<ul>' +
            '<li>Create your Clic Santé account now or Log in if you already have an account to be authorized to make an appointment despite the current identification problems.</li>' +
            '<li>You can go to a <strong>walk-in vaccination clinic</strong>. <a href="https://www.quebec.ca/en/health/health-issues/a-z/2019-coronavirus/progress-of-the-covid-19-vaccination#c104580" target="_blank">List of walk-in vaccination clinics</a>.</li>' +
            '</ul>'
        }
      }
    },
    queue: {
      communication: {
        message_type: {
          unknown: {
            title: 'Message important',
            message:
              '<p><strong>According to our records, no email address or cellphone number was entered when you made your last appointment. As a result, we cannot send your validation code.</strong></p>' +
              '<p>Here are your options:</p>' +
              '<ul>' +
              '<li>Create your Clic Santé account now or Log in if you already have an account to be authorized to make an appointment despite the current identification problems.</li>' +
              '<li>You can go to a <strong>walk-in vaccination clinic</strong>. <a href="https://www.quebec.ca/en/health/health-issues/a-z/2019-coronavirus/progress-of-the-covid-19-vaccination#c104580" target="_blank">List of walk-in vaccination clinics</a>.</li>' +
              '</ul>'
          }
        }
      }
    }
  },
  faq: {
    topics: {
      title: 'Your question is for which theme ?',
      items: {
        'vaccination-covid': 'Covid-19 vaccine',
        'depistage-covid': 'Covid-19 screening',
        'clic-sante-account': 'My Clic Santé account',
        'my-favorites-places': 'Favorites places'
      }
    },
    questions: {
      title: 'Your question is about ',
      subtitle:
        'Chose one from the below questions. You can go back whenever you want to preceding stage',
      button: 'Go back',
      topic_description: {
        'vaccination-covid': 'covid-19 vaccination',
        'depistage-covid': 'covid-19 screening',
        'clic-sante-account': ' Clic Santé account',
        'my-favorites-places': 'favorites places'
      }
    },
    noData: 'No data for subject'
  },
  logoutConfirmationModal: {
    title: 'Log out from my Clic Santé account',
    text: 'Are you sure you want to log out ?',
    cancel: 'Cancel',
    logout: 'YES, LOG ME OUT'
  },

  paths: {
    cancelAppt: 'cancel-appt',
    services: 'services',
    contactUs: 'contact-us',
    terms: 'terms',
    policy: 'privacy',
    cookiePolicy: 'cookie-policy',
    faq: 'answers-questions'
  },
  categoryDescription: {
    'consultation-pharmacist': {
      title: 'Welcome to the <strong>Pharmacy Consultation</strong> category!',
      text:
        '<p>This category allows you to book an appointment to consult a healthcare professional in a pharmacy.</p>' +
        '<p><strong>Select a subcategory</strong> to discover the insured services (in accordance with laws 31 and 41) available at a pharmacy near you. Certain restrictions may apply, depending on your eligibility criteria.</p>' +
        '</p>To find out about the many non-insured services also available in pharmacies, visit the various categories at <a href="https://www.clicsante.ca/en" target="_blank">www.clicsante.ca</a>.</p>'
    }
  },
  'category-information': {
    text:
      '<span>Please note that some categories have been modified. To access services related to COVID-19, please refer to the "Screening" or "Vaccination" categories.<br />' +
      'We also invite you to use the search bar for a more precise search. Enjoy your visit!</span>'
  }
}
